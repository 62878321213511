/**************************
Compass Text Replacement
*/
/**************************
Inline List
*/
/**************************
Inline Block List
*/
/**************************
horizontal-list
*/
/**************************
Bullets
*/
/* simple clearfix */
/**************************
@mixin for sprite
*/
/**************************
Compass Text Replacement
*/
/**************************
Inline List
*/
/**************************
Inline Block List
*/
/**************************
horizontal-list
*/
/**************************
Bullets
*/
/* simple clearfix */
/**************************
@mixin for sprite
*/
@keyframes fade-in {
	0% {
		display: none;
		opacity: 0;
	}
	1% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		display: block;
		opacity: 1;
	}
	99% {
		display: block;
		opacity: 0;
	}
	100% {
		display: none;
		opacity: 0;
	}
}

@keyframes zoom-in {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes zoom-in-hover {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.1);
	}
}

@keyframes sub_menu_fade_in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: .5;
	}
}

@keyframes pop-up {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.1);
	}
}

/* Slider */
.slick-loading .slick-list {
	background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
	font-family: 'slick';
	src: url("./fonts/slick.eot");
	src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
	font-weight: normal;
	font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
	padding: 0;
	border: 0 none;
	outline: none;
	position: absolute;
	line-height: 0px;
	font-size: 0px;
	background: transparent;
	color: transparent;
	display: block;
	width: 30px;
	height: 50px;
	cursor: pointer;
	top: 50%;
	margin-top: -15px;
	z-index: 10;
}

.slick-prev::before,
.slick-next::before {
	content: '';
	display: block;
	background: url("/common/img/icon/arrow/slick.png") 0 0 no-repeat transparent;
	background-size: 30px 50px;
	width: 30px;
	height: 50px;
	transition: transform 0.05s cubic-bezier(0.23, 1, 0.32, 1);
}

@media only screen and (max-width: 767px) {
	.slick-prev::before,
	.slick-next::before {
		width: 18px;
		height: 30px;
		background-size: 18px 30px;
	}
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
	outline: none;
}

.slick-prev:hover::before, .slick-prev:focus::before,
.slick-next:hover::before,
.slick-next:focus::before {
	transform: translateX(10%);
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
	position: absolute;
	pointer-events: none;
}

.slick-prev {
	transform: rotate(180deg);
	left: 0;
}

[dir='rtl'] .slick-prev {
	left: auto;
	right: 0;
}

.slick-prev.slick-disabled {
	left: auto;
}

.slick-next {
	left: auto;
	right: 0;
}

[dir='rtl'] .slick-next {
	right: 0;
	left: auto;
}

.slick-next.slick-disabled {
	left: auto;
}

/* Dots */
.slick-dots {
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
	pointer-events: none;
}

@media only screen and (max-width: 767px) {
	.slick-dots {
		bottom: 0;
	}
}

.slick-dots li {
	position: relative;
	display: inline-block;
	width: A;
	height: 14px;
	margin: 0 6px;
	padding: 0;
	cursor: pointer;
}

.slick-dots li button {
	pointer-events: auto;
	border: 0;
	outline: none;
	line-height: 0px;
	color: transparent;
	font-size: 0px;
	padding: 0;
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 14px;
	height: 14px;
	cursor: pointer;
	background-color: #fff;
	transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
	border-radius: 7px;
}

.slick-dots li button:focus {
	outline: none;
}

.slick-dots li button::before {
	content: '';
	display: block;
	border-radius: 4px;
	width: 8px;
	height: 8px;
	transform: scale(1);
	background-color: #b6b6b6;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -4px;
	margin-top: -4px;
	transition: all .1s linear;
}

.slick-dots li.slick-active button,
.slick-dots li button:hover,
.slick-dots li button:active {
	outline: none;
}

.slick-dots li.slick-active button::before,
.slick-dots li button:hover::before,
.slick-dots li button:active::before {
	transform: scale(1.2);
	background-color: #ffc41a;
}

.interview_area .slick-dots li.slick-active button::before, .interview_area
.slick-dots li button:hover::before, .interview_area
.slick-dots li button:active::before {
	background-color: #42210b;
}
